const clear = () => {
  if (typeof window !== 'undefined') {
    localStorage.clear();
  }
};

const getItem = (key) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
};

const removeItem = (key) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
};

export { clear, getItem, setItem, removeItem };
